import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { extract } from '../../utils/graphql'

import EmptyPage from '../../components/Templates/EmptyPage'
import WysiwygContent from '../../components/General/WysiwygContent'
import NoTranslation from '../../components/Templates/NoTranslation'
import { withPreview } from 'gatsby-source-prismic'

class HubspotForm extends Component {
  componentDidMount () {
    const script = document.createElement('script')
    script.src = '//js.hsforms.net/forms/shell.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '5068065',
          formId: '3dbc1662-6035-41b8-ab59-5bf82d7ce2a1',
          target: '#hubspotForm'
        })
      }
    })
  }

  render () {
    return (
      <div>
        <div id="hubspotForm"></div>
      </div>
    )
  }
}

const SpendSomeMoneyPage = ({ data, pageContext }) => {
  const { prismicTaskSpendSomeMoney } = data
  const page = prismicTaskSpendSomeMoney
  if (!page) return (<NoTranslation></NoTranslation>)

  const hydratedData = {
    lang: pageContext.locale,
    seo: extract.seo(page),
    mantelData: extract.mantel(page, {
      isThick: true
    })
  }
  return (
    <EmptyPage { ...hydratedData }>
      <HubspotForm />
      <div className="mt-4">
        <WysiwygContent>
          { page.data.content && page.data.content.html &&
            <div dangerouslySetInnerHTML={{ __html: page.data.content.html }}></div>
          }
        </WysiwygContent>
      </div>
    </EmptyPage>
  )
}

export default withPreview(SpendSomeMoneyPage)

export const pageQuery = graphql`
  query TaskSpendSomeMoneyByLocale( $locale: String! ) {
    prismicTaskSpendSomeMoney(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        seo_keywords {
          keyword {
            text
          }
        }
        seo_description {
          text
        }
        mantel_title {
          text
        }
        content {
          html
        }
      }
    }
  }
`
